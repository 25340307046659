import Link from 'next/link';

import Image from '@/components/cms/Image';
import { STORYBLOK_IMAGE_QUALITY } from '@/lib/constants';

interface IDictionary<T> {
  [key: string]: T;
}

const ImageWrapper = ({ blok }: any) => {
  const {
    image,
    maxDimension,
    sizeBy,
    disableLazyLoad,
    align,
    link,
    hoverFilterEffect,
    borderRadius,
    quality
  } = blok;

  const srcSetExample: IDictionary<string> = {
    767: `/m/${
      quality === '100' ? '1534x0/' : '767x0/'
    }filters:quality(${STORYBLOK_IMAGE_QUALITY})`,
    1024: `/m/${
      quality === '100' ? '2048x0/' : '1024x0/'
    }filters:quality(${STORYBLOK_IMAGE_QUALITY})`,
    1400: `/m/${
      quality === '100' ? '2640x0/' : '1320x0/'
    }filters:quality(${STORYBLOK_IMAGE_QUALITY})`
  };

  const ImageComp = (
    <Image
      src={image.filename}
      alt={image.alt}
      as="div"
      maxDimension={maxDimension}
      sizeBy={sizeBy}
      srcSet={srcSetExample}
      lazyLoad={!disableLazyLoad}
      align={align}
      hoverFilterEffect={hoverFilterEffect}
      borderRadius={borderRadius}
    />
  );

  const url = link && link?.url;
  if (url)
    return (
      <Link href={url}>
        <a target={url.includes('https') ? '_blank' : ''}>{ImageComp}</a>
      </Link>
    );

  return ImageComp;
};

export default ImageWrapper;
