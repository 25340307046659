import clsx from 'clsx';
import { ElementType } from 'react';

import { SITE_URL } from '@/lib/constants';

import styles from './Image.module.scss';

interface IDictionary<T> {
  [key: string]: T;
}

const getImageResponsive = (src: string, srcSet: IDictionary<string>) => {
  const imgUrl = src ?? '';

  const imgExtension = imgUrl.match(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i);
  if (!imgExtension) {
    return '';
  }

  const srcSetSizesArr = Object.keys(srcSet);

  const imgDimensions = imgUrl.match(/[0-9]+x[0-9]+/g)[0].split('x');
  const imageSrcSet = srcSetSizesArr.map((k) => `${imgUrl}${srcSet[k]} ${k}w`);
  const imageSizes = srcSetSizesArr.map((k, i) => {
    const isLast = i === srcSetSizesArr.length - 1;
    return isLast ? `${k}vw` : `(max-width:${k}px) ${k}vw`;
  });

  const isGif = imgUrl.endsWith('.gif');

  const placeholder = `${imgUrl}/m/${Math.ceil(parseInt(imgDimensions[0], 10) / 2)}x${Math.ceil(
    parseInt(imgDimensions[1], 10) / 2
  )}/filters:blur(70)`;

  return {
    style: isGif
      ? { backgroundColor: 'grey' }
      : { backgroundImage: `url("${placeholder}")`, backgroundSize: 'cover' },
    src: imgUrl,
    srcSet: imageSrcSet.join(', '),
    width: imgDimensions[0],
    height: imgDimensions[1],
    sizes: imageSizes.join(', ')
  };
};

export interface IImage {
  src: string;
  alt: string;
  as?: ElementType;
  maxDimension?: string;
  sizeBy?: 'width' | 'height' | 'fillContainer' | 'fitContainer';
  lazyLoad: boolean;
  srcSet: IDictionary<string>;
  align?: string;
  hoverFilterEffect?: boolean;
  borderRadius?: 's' | 'm' | 'l';
}

const inDev = process.env.NODE_ENV === 'development';
const host = `${SITE_URL}cdn/`;

const Image: React.FC<IImage> = ({
  src,
  alt,
  srcSet,
  as = 'div',
  sizeBy = 'width',
  maxDimension,
  lazyLoad = true,
  hoverFilterEffect = false,
  borderRadius,
  align
}) => {
  if (!src) return null;

  const imageIsSvg = src.endsWith('.svg');
  const Component = as;

  const _style: React.CSSProperties = {};
  if (sizeBy === 'width') {
    _style.width = maxDimension ? maxDimension : '100%';
  }
  if (sizeBy === 'height') _style.maxHeight = maxDimension ? maxDimension : '100%';

  const SVGRender = ({ src, style, align, styles, alt }) => {
    return <img src={src} alt={alt} style={style} className={clsx(align && styles.centerAlign)} />;
  };

  const ImageRender = ({ src, style, align, styles, alt }) => {
    const loadingAttr = lazyLoad ? 'lazy' : 'eager';
    const imageData = getImageResponsive(src, srcSet);
    return (
      <img
        loading={loadingAttr}
        alt={alt}
        style={style}
        {...imageData}
        className={clsx(align && styles.centerAlign)}
      />
    );
  };

  const rewriteSrc = inDev ? src : src.replace('https://a.storyblok.com/', host);

  return (
    <Component
      className={clsx(
        styles.image,
        sizeBy && styles[sizeBy],
        hoverFilterEffect && styles.hoverFilter,
        borderRadius && styles.borderRadius,
        borderRadius && styles[`borderRadius--${borderRadius}`]
      )}
      style={{ width: maxDimension ? maxDimension : '100%' }}
    >
      {imageIsSvg ? (
        <SVGRender alt={alt} style={_style} styles={styles} align={align} src={rewriteSrc} />
      ) : (
        <ImageRender alt={alt} style={_style} styles={styles} align={align} src={rewriteSrc} />
      )}
    </Component>
  );
};

export default Image;
